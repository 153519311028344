.horizontal-slider {
    height: 50px;
    margin-top: 30px;
    .example-thumb {
        top: 12px;
        width: 24px;
        height: 24px;
        line-height: 38px;
        text-align: center;
        background: #fff;
        border-radius: 50%;
        color: white;
        cursor: pointer;
        border: 2px solid #275efe;
    }
    .example-thumb.active  {
        border: 2px solid #275efe !important;
    }
    .example-track {
        top: 20px;
        height: 10px;
        border-radius: 5px;
    }
}

.example-track.example-track-0 {
    background: #EAECF0;
}

.example-track.example-track-1 {
    background: #155EEF;
}
.example-track.example-track-2 {
    background: #EAECF0;
}

// .horizontal-slider {
//     width: 100%;
//     max-width: 500px;
//     height: 50px;
//     margin-top: 30px;
//     .example-track {
//         top: 20px;
//         height: 10px;
//         .example-track-0, .example-track-2{
//             background: #EAECF0;
//         }
//         .example-track-1 {
//             background: #155EEF;
//         }
//     }
//     .example-thumb {
//         top: 1px;
//         width: 20px;
//         height: 20px;
//         line-height: 38px;
//         text-align: center;
//         background: #fff;
//         border-radius: 50%;
//         color: white;
//         cursor: pointer;
//         border: 2px solid #275efe;
//     }
// }