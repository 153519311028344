@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'SpaceGrotesk-Regular';
  src: url('../Font/SpaceGrotesk-Regular.ttf');
}

@font-face {
  font-family: 'SpaceGrotesk-Light';
  src: url('../Font/SpaceGrotesk-Light.ttf');
}

:root {
  --primary-font: 'SpaceGrotesk-Regular';
  --primary-font-light: 'SpaceGrotesk-Light';
  --secondary-font: 'Inter', sans-serif;
}

body {
  font-family: var(--secondary-font);
  line-height: 1.5;
  padding: 0;
  margin: 0;
  color: #475467;
  font-size: 14px;
  font-weight: 400;

  &>div {
    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background: #fff;
      transition: all 0.3s ease;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba($color: #004EEB, $alpha: 0.8);
      border-radius: 5px;
      margin-top: 8px;
      margin-bottom: 8px;
      cursor: pointer;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba($color: #004EEB, $alpha: 0.4);
    }
  }
}
.responsive-wrapper {
  @media (max-width:1023px) {
    .res-wrapper-flow  {
      position: relative;
      height: 100vh;
      overflow: hidden;
    }
  }
}

.container {
  max-width: 1280px;
}

// Custom Scroll Css Start

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
}

::-webkit-scrollbar-thumb:hover {
  background: #383838;
}


// Custom Scroll Css End
@media (max-width:1023px) {
  .navbar-toggle {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

a {

  &:focus,
  &:active {
    outline: none;
    border: none;
  }
}

ul {
  padding: 0;

  li {
    list-style: none;
  }
}

.modal-md {
  max-width: 500px;
  .modal-content {
     border-radius: 8px;
     background: var(--gray-100, #F2F4F7);
     .Group-itinerary-modal {
          h3 {
            font-weight: 600;
            font-size: 24px;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 0;
          }
    }
  }
  .modal-footer-item {
    display: flex;
    justify-content: flex-end;
    width: 60%;
    margin-left: auto;
    .light-btn {
      margin-left: 0;
      margin-right: 10px;
      background-color: transparent !important;
      color: #2970ff;
      border: none !important;
      font-size: 16px;
    }
  }
}
.modal-body-text {
  p {
   font-weight: 400 !important;
   font-size: 18px !important;
   line-height: 30px !important;
  }
}

// Modal wrapper css

.modal-wrapper {
  .modal-body {
    padding: 20px 30px;
    .light-btn {
      background-color: #fff;
        color: #2970ff;
        border: 1px solid #2970ff;
        margin-left: 30px;
    }
  }

  .modal-footer {
    width: 50%;
    border-top: 0;
    margin: 0 auto;
    padding: 0 0 20px;
  }
}
.modal-header {
  border-bottom: 0;
  padding: 15px 15px 0;
  .btn-close {
    color: #000;
    opacity: 1;
    font-size: 20px;
  }
}
.travel-modal {
  .modal-body {
    padding: 0px 30px;
    h3 {
      color: #344054;
      font-family: var(--secondary-font);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; 
      margin-bottom: 8px;
    }
    p {
      color: var(--gray-700, #344054);
      text-align: center;
      font-family: var(--secondary-font);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      margin: 0;
      @media (max-width:767px) {
        width: 100%;
      }
    }
  }
  .modal-footer {
    justify-content: flex-end;
    margin-bottom: 30px;
    border-top: none;
    padding: 0 30px;
    button {
      width: auto;
      padding: 10px 30px;
      &:first-child {
          border-radius: 8px;
          border: none;
          background: transparent;
          color: #0061ea;
          font-size: 18px;
      }
    }
  }
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: transparent;
}

.web-layout-body {
  min-height: calc(100vh - 63px);
  padding-top: 50px;
}

.loader-wrapper {
  width: 100%;
  position: fixed;
  top: -50px;
  left: 0;
  background-color: #0b0b0b;
  background-image: url('../images/loader-bg.png');
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
  z-index: 999;
  transition: all 0.4s linear 0s;
  animation: bg 0.9s cubic-bezier(0, 0.2, 0.8, 1);
}

.loader {
  svg {
     path:not(.icon) {
      fill:#fff;
     }
  }
}

@keyframes bg {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
.web-spinner-loader {
  background: url('../images/loader-bg.png');
  height:100vh;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  .spinner-loader {
    padding: 0 !important;
      .text-secondary {
          font-size: 18px !important;
          text-transform: capitalize;
      }
  }
}
.unsplash-text {
  position: absolute;
  right: 25px;
  bottom: 12px;
  font-size: 10px;
  color: #fff;
  font-weight: 400;
  background-color: rgba(0,0,0,0.6);
  padding: 3px 13px 5px;
  border-radius: 20px;
  @media (max-width:767px) {
     padding: 3px 13px 5px;
     font-size: 10px;
  }
  a {
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    @media (max-width:767px) {
      font-size: 12px;
    }
  }
} 
.unsplash-slider-text {
  @media (max-width:767px) {
    bottom: 60px;
  }
}